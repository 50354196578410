.wootton-map {
    pointer-events: none;
    cursor: pointer;
}

#landing-page-infowindow {
    width: 140px;
    color: var(--global-color-primary);
    text-align: center;
}

#landing-page-infowindow:hover {
    cursor: pointer;   
}

.wootton-logo {
    width: 130px;
}

/* Removes infowindow button */
.gm-style-iw + button {display: none !important;} 