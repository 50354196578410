/*# 5. Married*/
.married {
    font: var(--global-body-font);
	text-align: center;
    color: #777;
}
.married .container {
	padding: 60px 0 0
}
	.married .btn {
		margin: 30px 0 0
	}
	.marries_left {
		float: left;
		width: 33%;
		height: 270px;
		position: relative;
		left: -41px
	}
		.bride_block {
			width:100%;
			height:100%;
			position: absolute;
			transform:skew(-17deg,0);
			transform-origin:50% 50% 0;
			left:0;
			overflow:hidden
		}
			.bride_img {
				background: url("https://scontent-lcy1-1.xx.fbcdn.net/v/t1.18169-9/19059403_10209134894517116_7533644368846164074_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=174925&_nc_ohc=yPRoVh7vK90AX-JmmOn&_nc_ht=scontent-lcy1-1.xx&oh=00_AT8rDs7wBNzP0IxWxnuasFUsQfODebv1-ER2uWOjxKPAfQ&oe=61DD740D");
				background-size:cover;
				width:100%;
				height:100%;
				transform:skew(17deg,0);
				transform-origin:0 0 0 
			}
	.marries_center {
		float: left;
		width: 33%
	}
	.marries_right {
		float: right;
		width: 33%;
		height: 270px;
		position: relative;
		right: -41px
	}

		.groom_block {
			width:100%;
			height:100%;
			position: absolute;
			transform:skew(-17deg,0);
			transform-origin:50% 50% 0;
			right:0;
			overflow:hidden
		}
			.groom_img {
				background: url("http://bestday.maskandesign.com/html/template/css/../images/br5.jpg");
				background-size:cover;
				width:100%;
				height:100%;
				transform:skew(17deg,0);
				transform-origin:100% 100% 0 
			}
.married_coundown {
	font: 400 40px/48px Roboto Condensed ;
	margin: 20px 0 0 0
}
	.married_coundown .countdown-section {
		display: inline-block;
		vertical-align: top;
		text-align: center;
		margin: 0 10px;
		min-width: 60px;
		position: relative;
	}
		.married_coundown .countdown-amount {
			min-width: 60px;
			text-align: center;
		} 
	.married_coundown .countdown-section:before {
		display: inline-block;
		vertical-align: top;
		margin: 0 0 0 -15px;
		position: absolute;
		left: 0

	}
		.married_coundown .countdown-section:first-child:before {
			display: none
		}
	.married_coundown .countdown-period {
		font: 20px/24px Great Vibes;
		color: #c5c3be;
		display: block;
		text-transform: lowercase;
	}
.double_arrow {
	width: 188px;
	height: 25px;
	margin: 25px auto
}
.bride_title_block {
	font:46px/48px Great Vibes;
	text-align:center;
	margin:80px 0 0 0;
	color:#d9c6b1;
	position: absolute;
	left: 10%;
	text-align: center;
}
	.bride_title_block i {
		font: normal 300 15px/24px Roboto Condensed;
		color: #666;
		display: block;
		margin: -7px 0 0 0;
		padding: 0 0 0 10px;
	}
	.bride_title_block:before {
		position:absolute;
		display:block;
		content:"";
		width:1px;
		height:25px;
		background:#d9c6b1;
		transform:skew(-17deg,0);
		margin:-35px 0 0 20px;
		left:50%
	}
	.bride_title_block:after {
		position:absolute;
		display:block;
		content:"";
		width:1px;
		height:25px;
		background:#d9c6b1;
		transform:skew(-17deg,0);
		margin:7px 0 0 -9px;
		left:50%
	}
.groom_title_block {
	font:46px/48px Great Vibes;
	text-align: center;
	margin: 80px 0 0 0;
	color:#d9c6b1;
	position: absolute;
	right: 10%;
	text-align: center;
}
	.groom_title_block i {
		font: normal 300 15px/24px Roboto Condensed;
		color: #666;
		display: block;
		margin: -7px 0 0 0;
		padding: 0 0 0 10px;
	}
	.groom_title_block:before {
		position:absolute;
		display:block;
		content:"";
		width:1px;
		height:25px;
		background:#d9c6b1;
		transform:skew(-17deg,0);
		margin:-35px 0 0 20px;
		left:50%
	}
	.groom_title_block:after {
		position:absolute;
		display:block;
		content:"";
		width:1px;
		height:25px;
		background:#d9c6b1;
		transform:skew(-17deg,0);
		margin:7px 0 0 -9px;
		left:50%
	}
.married_photo img {
	border-radius: 50%;
	width: 100%;
	height: auto;
}
.married_photo {
	position: absolute;
	width: 200px;
	height: 200px;
	overflow: hidden;
	top: 0;
	margin: 59px 0 0 141px;
	z-index: 0;
}
	.groom_title_block .married_photo {
		margin: 59px 0 0 -55px;
	}

.im1 {
	width: 302px;
	height: 302px;
	border-radius: 50%;
	position: absolute;
	left:0;
	margin: 30px 0 0 0;
	background-image: url("https://storage.googleapis.com/matt-becca-test-image-assets/profile-matt.webp");
	background-position: center;
	background-size: cover;
}
	.im1:before, .im2:before {
		display: block;
		content: "";
		position: absolute;
		width: 302px;
		height: 302px;
		border: 15px solid rgba(255,255,255,0.4);
		border-radius: 50%;
	}
.im2 {
	width: 302px;
	height: 302px;
	border-radius: 50%;
	position: absolute;
	right: 0;
	margin: 30px 0 0 0;
	background-image: url("https://storage.googleapis.com/matt-becca-test-image-assets/profile-becca.jpeg");
	background-position: right;
	background-size: cover;
}
.im_arrows {
    position: absolute;
    width: 100%;
    height: 100%
}
.im_arrows:before {
    width: 39px;
    height: 77px;	
    display: block;
    content: "";
    position: absolute;
    top: -43px;
    right: 20px
}
.im_arrows:after {
    width: 13px;
    height: 38px;
    display: block;
    content: "";
    position: absolute;
    bottom: -34px;
    right: 120px
}	


/* Arrows */ 

.im_arrows {
    position: absolute;
    width: 100%;
    height: 100%
}
.im_arrows:before {
    width: 39px;
    height: 77px;	
    display: block;
    content: "";
    position: absolute;
    top: -43px;
    right: 20px
}
.im_arrows:after {
    width: 13px;
    height: 38px;
    display: block;
    content: "";
    position: absolute;
    bottom: -34px;
    right: 120px
}

.im_arrows:before {
	background: url("https://storage.googleapis.com/matt-becca-test-image-assets/page-assets/im_arrow_end.png");
	background-size: cover;
}
.im_arrows:after {
	background: url("https://storage.googleapis.com/matt-becca-test-image-assets/page-assets/im_arrow_start.png");
	background-size: cover;
}

/* Modal Elements */

.modal-container {
	font: var(--global-body-font);
	color: var(--global-text-color);
	background: url("https://img.freepik.com/free-vector/elegant-purple-alcohol-ink-background_41066-1460.jpg");
	background-position: center;
	background-size: cover;
	border-radius: 1%;
}

.modal-title {
	font-family: var(--global-section-heading-font);
	font-weight: 800;
	color: var(--global-color-primary);
	border: none;
}

.modal-title-center {
	text-align: center;
	text-indent: -15px;
	font: 52px/56px Great Vibes;
	color: var(--global-color-primary);
}

.modal-text {
	text-align: center;
	font-weight: 500;
}

.modal-corner-design {
	position: absolute;
	width: 150px;
	transform: rotate(325deg) translateX(-30px) translateY(-50px);
}

.modal-response {
	color: var(--global-color-secondary);
	border-color: var(--global-color-secondary);
	font-weight: 600;
	cursor: pointer;
	transition: all 0.8s;
}

.response-selected {
	background-color: var(--global-color-secondary);
	color: #fff;
}

#partner-switch:checked, #partner-switch:active, #partner-switch:checked:focus {
	background-color: var(--global-color-secondary) !important;
	border-color: var(--global-color-secondary) !important;
	box-shadow: var(--global-color-secondary) !important;
}

#partner-switch:hover {
	cursor: pointer;
}

#dietary-requirements {
	resize: none; 
	height: 20px;
    color: var(--global-text-color);
	font-size: 16px;
	font-weight: 300;
}

#dietary-requirements:focus {
	border-color: var(--global-color-primary);
    box-shadow: 0 0 5pt 2pt var(--global-color-primary);
}

.dietary-requirements-fade-in {
	visibility: visible;
  	opacity: 1;
  	transition: all 0.6s ease;
}

.dietary-requirements-fade-out {
	visibility: hidden;
  	opacity: 0;
  	transition: all 0.6s ease;
}

/* Responsive Media Queries */ 

@media (min-width: 768px) and (max-width: 1240px) {
	.im1{
		width: 200px;
		height: 200px;
	}

	.im2{
		width: 200px;
		height: 200px;
	}

	.im1:before, .im2:before {
		width: 200px;
		height: 200px;
	}
}

@media screen and (max-width: 990px) {
    .im1 {
		position: inherit;
		margin: 30px auto 0 auto;
	}

	.im2{
		position: inherit;
		margin: 30px auto 0 auto;
	}

	.im_arrows {
		display: none;
	}

	.im_arrows:before {
		display: none;
	}
	
	.im_arrows:after {
		display: none;
	}	
}

@media screen and (max-width: 768px) {
    .im1 {
		position: inherit;
		margin: 0px auto 40px auto;
		background-image: url("https://storage.googleapis.com/matt-becca-test-image-assets/profile-picture-small.jpg");
	}

	/* prevents fade left on mobie screens */
	.im1[data-aos=fade-left]{
		transform: translateX(0) !important;
	}

	.im2{
		display: none;
	}

	.im_arrows {
		display: none;
	}

	.im_arrows:before {
		display: none;
	}
	
	.im_arrows:after {
		display: none;
	}	
}
