h2 {
    font: 40px/46px Great Vibes;
	text-align: center;
	margin: 20px 0 20px 0;
    font-weight: normal;
    line-height: 1;
    color: #777;
}

h2:after {
	display: block;
	content: "";
	width: 16px;
	height: 26px;
	margin: 12px auto 0
}

h2 span {
    font:100 24px/32px Roboto Condensed;
    padding: 0 13px 0 0;
    font-weight: 20;
}

h2 span:after {
    display:inline-block;
    content:"";
    position: absolute;
    width:1px;
    height:40px;
    transform:rotate(17deg);
    margin: 0px 0 0 9px;
    background:#B70062;
}