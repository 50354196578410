h1 {
  font-family: 'Parisienne', cursive;
  text-align: center;
  margin: 20px 0 20px 0;
  font-weight: normal;
  line-height: 1;
  color: var(--global-color-primary);
}

h2 {
  font-family: 'Parisienne', cursive;
  text-align: center;
  padding: 0 13px 0 0;
  font-weight: 20;
  color: var(--global-color-primary);
}

h3 {
  font-family: 'Roboto' sans-serif;
  font-size: 14px;
  padding: 0 13px 0 0;
  font-weight: 12;
  color: var(--global-text-color);
}

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.login-icon {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

/* ==== Phone Number Field ==== */

.phone-number-container {
  margin: 0 auto;
  display: block;
}

.phone-number {
  margin: 10px auto 20px auto;
    display: block;
    position: relative;
    width: 70%;
    font-size: 16px;
    font-family: 'Stag Sans Light';
    padding: 12px 10px 10px 36px;
    border: 2px solid #dadada;
    box-sizing: border-box;
    outline: none;
    transition: all 0.3s ease;
}

.phone-number:focus {
    outline: none;
    border-color: var(--global-color-primary);
    box-shadow: 0 0 10px var(--global-color-primary);
    width: 90%;
}

.extension-code-dropdown {
  font-family: var(--global-font-family);
  font-size: var(--global-font-size);
}
