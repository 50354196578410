.home_intro {
	background-size: cover;
	width: 100vw;
	height: 100vh;
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
}

.video-container {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.firefly-container {
    z-index: 5;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.4);
}

.white_txt {
	color:#fff;
	background-color:#65666b
}
	.white_txt h2 {
		color:#fff
	}
	

.home_txt {
	position: absolute;
	margin:0;
	width:100%;
	z-index:4;
	bottom: 60px;
    opacity: 1;
}

.title {
    text-align: center;
    text-indent:-15px;
    font: 52px/56px Great Vibes;
}

.bullet {
    background: url("https://storage.googleapis.com/matt-becca-test-image-assets/page-assets/bullet.png");
    background-size: cover;
}

.bullet {
	display: block;
	width: 16px;
	height: 26px;
	margin: 8px 0 0 16px;
    text-align: center;
	margin: 5px auto;
}

.bullet span {
    position: absolute;
    margin: -3px 0 0 -35px;
    font: 400 18px/28px Roboto Condensed;
}

.bullet span b {
    font: 400 18px/28px Roboto Condensed;
    display: inline-block;
    margin: 0 0 0 30px;
}

.year {
    font:300 16px/28px Roboto Condensed;
    color:#fff;
    text-align: center;
}

.intro_down span {
    display: block;
    color:#fff;
    margin: 0 auto;
    position: relative;
    text-align: center
}

.down-arrow {
    width: 130px;
    height: 70px;
    position: relative;
    margin-left: 5px;
}