.playlist-item {
    margin-top: 20px;  
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transition: all 0.5s;
    border-width: 1px 1px 1px 0;
    border-radius: 0 0 30px 30px;
    font: var(--global-body-font);
}

.playlist-item:hover {
    box-shadow: 0 14px 28px rgba(111, 48, 146, 0.5), 0 10px 10px rgba(111, 48, 146, 0.22);
}

.album-cover {
    height: 100%;
}

.voting-arrow {
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.vote-number {
    text-align: center;
}

@media screen and (max-width: 992px) {
    .vote-arrow {
        padding-right: 100px;
    }
}