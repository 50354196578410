.firefly {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none; }
    .firefly::before, .firefly::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform-origin: -10vw; }
    .firefly::before {
      background: white;
      opacity: 1;
      animation: drift ease alternate infinite; }
    .firefly::after {
      background: white;
      opacity: 0;
      box-shadow: 0 0 0vw 0vw var(--global-color-primary);
      animation: drift ease alternate infinite, flash ease infinite; }
  
  .firefly:nth-child(1) {
    animation-name: move1; }
    .firefly:nth-child(1)::before {
      animation-duration: 10s; }
    .firefly:nth-child(1)::after {
      animation-duration: 10s, 9949ms;
      animation-delay: 0ms, 8090ms; }
  
  @keyframes move1 {
    0% {
      transform: translateX(-11vw) translateY(14vh) scale(0.83); }
    4.7619% {
      transform: translateX(-31vw) translateY(-19vh) scale(0.91); }
    9.52381% {
      transform: translateX(31vw) translateY(24vh) scale(0.52); }
    14.28571% {
      transform: translateX(13vw) translateY(14vh) scale(0.82); }
    19.04762% {
      transform: translateX(10vw) translateY(38vh) scale(0.57); }
    23.80952% {
      transform: translateX(-20vw) translateY(32vh) scale(0.58); }
    28.57143% {
      transform: translateX(-26vw) translateY(35vh) scale(0.81); }
    33.33333% {
      transform: translateX(7vw) translateY(11vh) scale(0.68); }
    38.09524% {
      transform: translateX(4vw) translateY(37vh) scale(0.69); }
    42.85714% {
      transform: translateX(27vw) translateY(-47vh) scale(0.56); }
    47.61905% {
      transform: translateX(20vw) translateY(33vh) scale(0.42); }
    52.38095% {
      transform: translateX(-39vw) translateY(24vh) scale(0.37); }
    57.14286% {
      transform: translateX(-43vw) translateY(1vh) scale(0.75); }
    61.90476% {
      transform: translateX(23vw) translateY(-19vh) scale(0.31); }
    66.66667% {
      transform: translateX(-21vw) translateY(30vh) scale(0.26); }
    71.42857% {
      transform: translateX(10vw) translateY(-48vh) scale(0.95); }
    76.19048% {
      transform: translateX(16vw) translateY(-4vh) scale(0.98); }
    80.95238% {
      transform: translateX(34vw) translateY(-49vh) scale(0.86); }
    85.71429% {
      transform: translateX(-14vw) translateY(-39vh) scale(0.75); }
    90.47619% {
      transform: translateX(2vw) translateY(-32vh) scale(0.43); }
    95.2381% {
      transform: translateX(-1vw) translateY(1vh) scale(0.87); }
    100% {
      transform: translateX(40vw) translateY(18vh) scale(0.41); } }
  
  .firefly:nth-child(2) {
    animation-name: move2; }
    .firefly:nth-child(2)::before {
      animation-duration: 16s; }
    .firefly:nth-child(2)::after {
      animation-duration: 16s, 7008ms;
      animation-delay: 0ms, 4457ms; }
  
  @keyframes move2 {
    0% {
      transform: translateX(24vw) translateY(-7vh) scale(0.36); }
    3.7037% {
      transform: translateX(-14vw) translateY(49vh) scale(0.29); }
    7.40741% {
      transform: translateX(-6vw) translateY(-19vh) scale(0.59); }
    11.11111% {
      transform: translateX(-5vw) translateY(43vh) scale(0.79); }
    14.81481% {
      transform: translateX(-10vw) translateY(-12vh) scale(0.55); }
    18.51852% {
      transform: translateX(31vw) translateY(-44vh) scale(0.38); }
    22.22222% {
      transform: translateX(-7vw) translateY(33vh) scale(0.58); }
    25.92593% {
      transform: translateX(44vw) translateY(39vh) scale(0.57); }
    29.62963% {
      transform: translateX(45vw) translateY(37vh) scale(0.89); }
    33.33333% {
      transform: translateX(3vw) translateY(15vh) scale(0.77); }
    37.03704% {
      transform: translateX(21vw) translateY(47vh) scale(0.36); }
    40.74074% {
      transform: translateX(-47vw) translateY(-37vh) scale(0.72); }
    44.44444% {
      transform: translateX(21vw) translateY(-42vh) scale(0.86); }
    48.14815% {
      transform: translateX(48vw) translateY(15vh) scale(0.68); }
    51.85185% {
      transform: translateX(49vw) translateY(10vh) scale(0.69); }
    55.55556% {
      transform: translateX(9vw) translateY(-48vh) scale(0.84); }
    59.25926% {
      transform: translateX(14vw) translateY(44vh) scale(0.28); }
    62.96296% {
      transform: translateX(50vw) translateY(-24vh) scale(0.54); }
    66.66667% {
      transform: translateX(-38vw) translateY(-40vh) scale(0.28); }
    70.37037% {
      transform: translateX(36vw) translateY(-9vh) scale(0.95); }
    74.07407% {
      transform: translateX(-45vw) translateY(38vh) scale(0.67); }
    77.77778% {
      transform: translateX(-27vw) translateY(-45vh) scale(0.54); }
    81.48148% {
      transform: translateX(22vw) translateY(24vh) scale(0.88); }
    85.18519% {
      transform: translateX(3vw) translateY(-40vh) scale(0.34); }
    88.88889% {
      transform: translateX(11vw) translateY(46vh) scale(0.83); }
    92.59259% {
      transform: translateX(27vw) translateY(19vh) scale(0.84); }
    96.2963% {
      transform: translateX(21vw) translateY(30vh) scale(0.79); }
    100% {
      transform: translateX(-37vw) translateY(46vh) scale(0.77); } }
  
  .firefly:nth-child(3) {
    animation-name: move3; }
    .firefly:nth-child(3)::before {
      animation-duration: 9s; }
    .firefly:nth-child(3)::after {
      animation-duration: 9s, 9786ms;
      animation-delay: 0ms, 7239ms; }
  
  @keyframes move3 {
    0% {
      transform: translateX(-33vw) translateY(-38vh) scale(0.82); }
    5.26316% {
      transform: translateX(15vw) translateY(-28vh) scale(0.77); }
    10.52632% {
      transform: translateX(2vw) translateY(-49vh) scale(0.82); }
    15.78947% {
      transform: translateX(-6vw) translateY(15vh) scale(0.66); }
    21.05263% {
      transform: translateX(37vw) translateY(18vh) scale(0.29); }
    26.31579% {
      transform: translateX(21vw) translateY(-6vh) scale(0.37); }
    31.57895% {
      transform: translateX(-45vw) translateY(-3vh) scale(0.61); }
    36.84211% {
      transform: translateX(14vw) translateY(31vh) scale(0.39); }
    42.10526% {
      transform: translateX(22vw) translateY(-17vh) scale(0.46); }
    47.36842% {
      transform: translateX(-33vw) translateY(33vh) scale(0.89); }
    52.63158% {
      transform: translateX(-27vw) translateY(34vh) scale(0.36); }
    57.89474% {
      transform: translateX(4vw) translateY(37vh) scale(0.58); }
    63.15789% {
      transform: translateX(27vw) translateY(-44vh) scale(0.28); }
    68.42105% {
      transform: translateX(-18vw) translateY(27vh) scale(0.64); }
    73.68421% {
      transform: translateX(2vw) translateY(-30vh) scale(0.47); }
    78.94737% {
      transform: translateX(11vw) translateY(-32vh) scale(0.7); }
    84.21053% {
      transform: translateX(-1vw) translateY(-49vh) scale(0.88); }
    89.47368% {
      transform: translateX(-5vw) translateY(-30vh) scale(0.88); }
    94.73684% {
      transform: translateX(8vw) translateY(-25vh) scale(0.46); }
    100% {
      transform: translateX(18vw) translateY(-45vh) scale(0.62); } }
  
  .firefly:nth-child(4) {
    animation-name: move4; }
    .firefly:nth-child(4)::before {
      animation-duration: 17s; }
    .firefly:nth-child(4)::after {
      animation-duration: 17s, 10515ms;
      animation-delay: 0ms, 7592ms; }
  
  @keyframes move4 {
    0% {
      transform: translateX(-5vw) translateY(25vh) scale(0.34); }
    4% {
      transform: translateX(34vw) translateY(21vh) scale(0.5); }
    8% {
      transform: translateX(-37vw) translateY(-20vh) scale(0.81); }
    12% {
      transform: translateX(8vw) translateY(35vh) scale(0.96); }
    16% {
      transform: translateX(9vw) translateY(-26vh) scale(0.41); }
    20% {
      transform: translateX(-13vw) translateY(50vh) scale(0.98); }
    24% {
      transform: translateX(37vw) translateY(29vh) scale(0.55); }
    28% {
      transform: translateX(-15vw) translateY(-48vh) scale(0.88); }
    32% {
      transform: translateX(-19vw) translateY(29vh) scale(0.82); }
    36% {
      transform: translateX(44vw) translateY(28vh) scale(0.31); }
    40% {
      transform: translateX(13vw) translateY(-41vh) scale(0.54); }
    44% {
      transform: translateX(25vw) translateY(15vh) scale(0.45); }
    48% {
      transform: translateX(20vw) translateY(28vh) scale(0.51); }
    52% {
      transform: translateX(5vw) translateY(47vh) scale(0.67); }
    56% {
      transform: translateX(-37vw) translateY(-26vh) scale(0.85); }
    60% {
      transform: translateX(38vw) translateY(-39vh) scale(0.94); }
    64% {
      transform: translateX(36vw) translateY(5vh) scale(0.56); }
    68% {
      transform: translateX(-7vw) translateY(-40vh) scale(0.69); }
    72% {
      transform: translateX(47vw) translateY(32vh) scale(0.57); }
    76% {
      transform: translateX(11vw) translateY(-19vh) scale(0.57); }
    80% {
      transform: translateX(4vw) translateY(10vh) scale(0.49); }
    84% {
      transform: translateX(-14vw) translateY(-47vh) scale(0.89); }
    88% {
      transform: translateX(-26vw) translateY(29vh) scale(0.9); }
    92% {
      transform: translateX(25vw) translateY(14vh) scale(0.32); }
    96% {
      transform: translateX(-46vw) translateY(31vh) scale(0.68); }
    100% {
      transform: translateX(-26vw) translateY(-11vh) scale(0.39); } }
  
  .firefly:nth-child(5) {
    animation-name: move5; }
    .firefly:nth-child(5)::before {
      animation-duration: 16s; }
    .firefly:nth-child(5)::after {
      animation-duration: 16s, 8483ms;
      animation-delay: 0ms, 3302ms; }
  
  @keyframes move5 {
    0% {
      transform: translateX(6vw) translateY(-19vh) scale(0.35); }
    5.88235% {
      transform: translateX(10vw) translateY(15vh) scale(0.85); }
    11.76471% {
      transform: translateX(14vw) translateY(-4vh) scale(0.71); }
    17.64706% {
      transform: translateX(-48vw) translateY(-28vh) scale(0.77); }
    23.52941% {
      transform: translateX(-21vw) translateY(-10vh) scale(0.9); }
    29.41176% {
      transform: translateX(-30vw) translateY(40vh) scale(0.5); }
    35.29412% {
      transform: translateX(37vw) translateY(27vh) scale(0.74); }
    41.17647% {
      transform: translateX(-13vw) translateY(44vh) scale(0.7); }
    47.05882% {
      transform: translateX(34vw) translateY(-47vh) scale(0.79); }
    52.94118% {
      transform: translateX(-23vw) translateY(30vh) scale(0.84); }
    58.82353% {
      transform: translateX(-35vw) translateY(-49vh) scale(0.92); }
    64.70588% {
      transform: translateX(32vw) translateY(-10vh) scale(0.6); }
    70.58824% {
      transform: translateX(-26vw) translateY(-37vh) scale(0.69); }
    76.47059% {
      transform: translateX(-46vw) translateY(43vh) scale(0.53); }
    82.35294% {
      transform: translateX(20vw) translateY(-49vh) scale(0.71); }
    88.23529% {
      transform: translateX(-38vw) translateY(2vh) scale(0.99); }
    94.11765% {
      transform: translateX(45vw) translateY(-40vh) scale(0.82); }
    100% {
      transform: translateX(26vw) translateY(9vh) scale(0.89); } }
  
  .firefly:nth-child(6) {
    animation-name: move6; }
    .firefly:nth-child(6)::before {
      animation-duration: 13s; }
    .firefly:nth-child(6)::after {
      animation-duration: 13s, 8386ms;
      animation-delay: 0ms, 7458ms; }
  
  @keyframes move6 {
    0% {
      transform: translateX(16vw) translateY(18vh) scale(0.38); }
    3.84615% {
      transform: translateX(45vw) translateY(-29vh) scale(0.71); }
    7.69231% {
      transform: translateX(-20vw) translateY(3vh) scale(0.74); }
    11.53846% {
      transform: translateX(21vw) translateY(47vh) scale(0.68); }
    15.38462% {
      transform: translateX(2vw) translateY(38vh) scale(0.89); }
    19.23077% {
      transform: translateX(-9vw) translateY(-5vh) scale(0.76); }
    23.07692% {
      transform: translateX(-8vw) translateY(-31vh) scale(0.71); }
    26.92308% {
      transform: translateX(12vw) translateY(29vh) scale(0.83); }
    30.76923% {
      transform: translateX(45vw) translateY(4vh) scale(0.63); }
    34.61538% {
      transform: translateX(-11vw) translateY(31vh) scale(0.6); }
    38.46154% {
      transform: translateX(-39vw) translateY(20vh) scale(0.47); }
    42.30769% {
      transform: translateX(34vw) translateY(38vh) scale(0.61); }
    46.15385% {
      transform: translateX(28vw) translateY(34vh) scale(0.45); }
    50% {
      transform: translateX(-15vw) translateY(-9vh) scale(0.26); }
    53.84615% {
      transform: translateX(-34vw) translateY(-32vh) scale(0.64); }
    57.69231% {
      transform: translateX(-34vw) translateY(5vh) scale(0.56); }
    61.53846% {
      transform: translateX(-12vw) translateY(-28vh) scale(0.7); }
    65.38462% {
      transform: translateX(46vw) translateY(35vh) scale(0.73); }
    69.23077% {
      transform: translateX(-27vw) translateY(-9vh) scale(0.77); }
    73.07692% {
      transform: translateX(10vw) translateY(30vh) scale(0.82); }
    76.92308% {
      transform: translateX(-8vw) translateY(-16vh) scale(0.36); }
    80.76923% {
      transform: translateX(-48vw) translateY(-18vh) scale(0.94); }
    84.61538% {
      transform: translateX(41vw) translateY(24vh) scale(0.99); }
    88.46154% {
      transform: translateX(-44vw) translateY(24vh) scale(0.8); }
    92.30769% {
      transform: translateX(-26vw) translateY(44vh) scale(0.34); }
    96.15385% {
      transform: translateX(-3vw) translateY(-23vh) scale(0.5); }
    100% {
      transform: translateX(-20vw) translateY(12vh) scale(0.8); } }
  
  .firefly:nth-child(7) {
    animation-name: move7; }
    .firefly:nth-child(7)::before {
      animation-duration: 13s; }
    .firefly:nth-child(7)::after {
      animation-duration: 13s, 8316ms;
      animation-delay: 0ms, 3657ms; }
  
  @keyframes move7 {
    0% {
      transform: translateX(-48vw) translateY(-29vh) scale(0.76); }
    5.55556% {
      transform: translateX(19vw) translateY(30vh) scale(0.99); }
    11.11111% {
      transform: translateX(-22vw) translateY(4vh) scale(0.44); }
    16.66667% {
      transform: translateX(-10vw) translateY(45vh) scale(0.34); }
    22.22222% {
      transform: translateX(26vw) translateY(17vh) scale(0.78); }
    27.77778% {
      transform: translateX(-42vw) translateY(-37vh) scale(0.89); }
    33.33333% {
      transform: translateX(43vw) translateY(2vh) scale(0.41); }
    38.88889% {
      transform: translateX(8vw) translateY(27vh) scale(0.51); }
    44.44444% {
      transform: translateX(-5vw) translateY(9vh) scale(0.8); }
    50% {
      transform: translateX(2vw) translateY(3vh) scale(0.57); }
    55.55556% {
      transform: translateX(5vw) translateY(36vh) scale(0.33); }
    61.11111% {
      transform: translateX(-12vw) translateY(-17vh) scale(0.47); }
    66.66667% {
      transform: translateX(40vw) translateY(20vh) scale(0.79); }
    72.22222% {
      transform: translateX(29vw) translateY(3vh) scale(0.74); }
    77.77778% {
      transform: translateX(-38vw) translateY(25vh) scale(0.34); }
    83.33333% {
      transform: translateX(24vw) translateY(7vh) scale(0.85); }
    88.88889% {
      transform: translateX(45vw) translateY(-19vh) scale(0.53); }
    94.44444% {
      transform: translateX(45vw) translateY(11vh) scale(0.47); }
    100% {
      transform: translateX(45vw) translateY(16vh) scale(0.86); } }
  
  .firefly:nth-child(8) {
    animation-name: move8; }
    .firefly:nth-child(8)::before {
      animation-duration: 16s; }
    .firefly:nth-child(8)::after {
      animation-duration: 16s, 10710ms;
      animation-delay: 0ms, 7186ms; }
  
  @keyframes move8 {
    0% {
      transform: translateX(0vw) translateY(-33vh) scale(0.97); }
    4.7619% {
      transform: translateX(49vw) translateY(-13vh) scale(0.39); }
    9.52381% {
      transform: translateX(27vw) translateY(11vh) scale(0.61); }
    14.28571% {
      transform: translateX(-33vw) translateY(16vh) scale(0.9); }
    19.04762% {
      transform: translateX(46vw) translateY(43vh) scale(0.33); }
    23.80952% {
      transform: translateX(22vw) translateY(-30vh) scale(0.51); }
    28.57143% {
      transform: translateX(-12vw) translateY(-41vh) scale(0.85); }
    33.33333% {
      transform: translateX(11vw) translateY(35vh) scale(0.3); }
    38.09524% {
      transform: translateX(28vw) translateY(37vh) scale(0.75); }
    42.85714% {
      transform: translateX(10vw) translateY(-27vh) scale(0.51); }
    47.61905% {
      transform: translateX(14vw) translateY(37vh) scale(0.59); }
    52.38095% {
      transform: translateX(-20vw) translateY(-8vh) scale(0.47); }
    57.14286% {
      transform: translateX(-18vw) translateY(12vh) scale(0.26); }
    61.90476% {
      transform: translateX(41vw) translateY(-29vh) scale(0.38); }
    66.66667% {
      transform: translateX(-7vw) translateY(-44vh) scale(0.93); }
    71.42857% {
      transform: translateX(-1vw) translateY(-26vh) scale(0.62); }
    76.19048% {
      transform: translateX(16vw) translateY(39vh) scale(0.65); }
    80.95238% {
      transform: translateX(-23vw) translateY(36vh) scale(0.27); }
    85.71429% {
      transform: translateX(21vw) translateY(44vh) scale(0.87); }
    90.47619% {
      transform: translateX(-42vw) translateY(-35vh) scale(0.35); }
    95.2381% {
      transform: translateX(-15vw) translateY(-41vh) scale(0.48); }
    100% {
      transform: translateX(10vw) translateY(24vh) scale(0.6); } }
  
  .firefly:nth-child(9) {
    animation-name: move9; }
    .firefly:nth-child(9)::before {
      animation-duration: 13s; }
    .firefly:nth-child(9)::after {
      animation-duration: 13s, 6648ms;
      animation-delay: 0ms, 1069ms; }
  
  @keyframes move9 {
    0% {
      transform: translateX(-2vw) translateY(-24vh) scale(0.91); }
    3.57143% {
      transform: translateX(4vw) translateY(42vh) scale(0.74); }
    7.14286% {
      transform: translateX(-3vw) translateY(-44vh) scale(0.93); }
    10.71429% {
      transform: translateX(-9vw) translateY(-27vh) scale(0.54); }
    14.28571% {
      transform: translateX(15vw) translateY(-49vh) scale(0.35); }
    17.85714% {
      transform: translateX(46vw) translateY(-2vh) scale(0.76); }
    21.42857% {
      transform: translateX(39vw) translateY(-39vh) scale(0.9); }
    25% {
      transform: translateX(-20vw) translateY(0vh) scale(0.38); }
    28.57143% {
      transform: translateX(45vw) translateY(1vh) scale(0.71); }
    32.14286% {
      transform: translateX(-40vw) translateY(19vh) scale(0.31); }
    35.71429% {
      transform: translateX(-13vw) translateY(21vh) scale(0.69); }
    39.28571% {
      transform: translateX(22vw) translateY(-3vh) scale(0.44); }
    42.85714% {
      transform: translateX(32vw) translateY(-42vh) scale(0.32); }
    46.42857% {
      transform: translateX(42vw) translateY(-41vh) scale(0.65); }
    50% {
      transform: translateX(2vw) translateY(-20vh) scale(0.74); }
    53.57143% {
      transform: translateX(31vw) translateY(-25vh) scale(0.86); }
    57.14286% {
      transform: translateX(-37vw) translateY(-8vh) scale(0.61); }
    60.71429% {
      transform: translateX(-2vw) translateY(-8vh) scale(0.41); }
    64.28571% {
      transform: translateX(-37vw) translateY(-12vh) scale(0.87); }
    67.85714% {
      transform: translateX(27vw) translateY(13vh) scale(0.51); }
    71.42857% {
      transform: translateX(13vw) translateY(-45vh) scale(0.42); }
    75% {
      transform: translateX(-15vw) translateY(-32vh) scale(0.39); }
    78.57143% {
      transform: translateX(-35vw) translateY(-21vh) scale(0.77); }
    82.14286% {
      transform: translateX(19vw) translateY(5vh) scale(0.39); }
    85.71429% {
      transform: translateX(-19vw) translateY(43vh) scale(0.38); }
    89.28571% {
      transform: translateX(37vw) translateY(48vh) scale(0.53); }
    92.85714% {
      transform: translateX(-27vw) translateY(11vh) scale(0.85); }
    96.42857% {
      transform: translateX(-22vw) translateY(33vh) scale(0.85); }
    100% {
      transform: translateX(-32vw) translateY(25vh) scale(0.67); } }
  
  .firefly:nth-child(10) {
    animation-name: move10; }
    .firefly:nth-child(10)::before {
      animation-duration: 17s; }
    .firefly:nth-child(10)::after {
      animation-duration: 17s, 5775ms;
      animation-delay: 0ms, 7942ms; }
  
  @keyframes move10 {
    0% {
      transform: translateX(44vw) translateY(-15vh) scale(0.68); }
    3.84615% {
      transform: translateX(-31vw) translateY(-44vh) scale(0.52); }
    7.69231% {
      transform: translateX(-41vw) translateY(28vh) scale(0.68); }
    11.53846% {
      transform: translateX(-27vw) translateY(12vh) scale(0.37); }
    15.38462% {
      transform: translateX(-4vw) translateY(-36vh) scale(0.55); }
    19.23077% {
      transform: translateX(29vw) translateY(-32vh) scale(0.87); }
    23.07692% {
      transform: translateX(3vw) translateY(15vh) scale(0.44); }
    26.92308% {
      transform: translateX(5vw) translateY(37vh) scale(0.3); }
    30.76923% {
      transform: translateX(-22vw) translateY(-34vh) scale(0.73); }
    34.61538% {
      transform: translateX(-33vw) translateY(5vh) scale(0.8); }
    38.46154% {
      transform: translateX(-15vw) translateY(-29vh) scale(0.74); }
    42.30769% {
      transform: translateX(-11vw) translateY(4vh) scale(0.34); }
    46.15385% {
      transform: translateX(8vw) translateY(-28vh) scale(0.48); }
    50% {
      transform: translateX(16vw) translateY(-1vh) scale(0.47); }
    53.84615% {
      transform: translateX(-36vw) translateY(19vh) scale(0.96); }
    57.69231% {
      transform: translateX(45vw) translateY(-8vh) scale(0.87); }
    61.53846% {
      transform: translateX(-31vw) translateY(-16vh) scale(0.3); }
    65.38462% {
      transform: translateX(40vw) translateY(3vh) scale(0.31); }
    69.23077% {
      transform: translateX(-30vw) translateY(43vh) scale(0.85); }
    73.07692% {
      transform: translateX(-11vw) translateY(3vh) scale(0.99); }
    76.92308% {
      transform: translateX(-14vw) translateY(25vh) scale(0.84); }
    80.76923% {
      transform: translateX(38vw) translateY(-9vh) scale(0.82); }
    84.61538% {
      transform: translateX(45vw) translateY(15vh) scale(0.98); }
    88.46154% {
      transform: translateX(45vw) translateY(-30vh) scale(0.39); }
    92.30769% {
      transform: translateX(-41vw) translateY(25vh) scale(0.37); }
    96.15385% {
      transform: translateX(39vw) translateY(38vh) scale(0.45); }
    100% {
      transform: translateX(0vw) translateY(39vh) scale(0.54); } }
  
  .firefly:nth-child(11) {
    animation-name: move11; }
    .firefly:nth-child(11)::before {
      animation-duration: 14s; }
    .firefly:nth-child(11)::after {
      animation-duration: 14s, 5749ms;
      animation-delay: 0ms, 5822ms; }
  
  @keyframes move11 {
    0% {
      transform: translateX(29vw) translateY(23vh) scale(0.67); }
    3.57143% {
      transform: translateX(32vw) translateY(9vh) scale(0.38); }
    7.14286% {
      transform: translateX(-15vw) translateY(-45vh) scale(0.37); }
    10.71429% {
      transform: translateX(-10vw) translateY(37vh) scale(0.68); }
    14.28571% {
      transform: translateX(38vw) translateY(-42vh) scale(0.75); }
    17.85714% {
      transform: translateX(47vw) translateY(36vh) scale(0.48); }
    21.42857% {
      transform: translateX(39vw) translateY(19vh) scale(0.47); }
    25% {
      transform: translateX(39vw) translateY(15vh) scale(0.27); }
    28.57143% {
      transform: translateX(-24vw) translateY(-40vh) scale(0.32); }
    32.14286% {
      transform: translateX(-5vw) translateY(-40vh) scale(0.61); }
    35.71429% {
      transform: translateX(-19vw) translateY(-40vh) scale(0.42); }
    39.28571% {
      transform: translateX(-16vw) translateY(26vh) scale(0.82); }
    42.85714% {
      transform: translateX(-42vw) translateY(-27vh) scale(0.86); }
    46.42857% {
      transform: translateX(-32vw) translateY(4vh) scale(0.52); }
    50% {
      transform: translateX(38vw) translateY(2vh) scale(0.3); }
    53.57143% {
      transform: translateX(0vw) translateY(-47vh) scale(0.72); }
    57.14286% {
      transform: translateX(28vw) translateY(19vh) scale(0.3); }
    60.71429% {
      transform: translateX(38vw) translateY(31vh) scale(0.8); }
    64.28571% {
      transform: translateX(-17vw) translateY(7vh) scale(0.74); }
    67.85714% {
      transform: translateX(-42vw) translateY(46vh) scale(0.93); }
    71.42857% {
      transform: translateX(-9vw) translateY(8vh) scale(0.46); }
    75% {
      transform: translateX(-9vw) translateY(42vh) scale(0.84); }
    78.57143% {
      transform: translateX(-46vw) translateY(-5vh) scale(0.29); }
    82.14286% {
      transform: translateX(42vw) translateY(45vh) scale(0.39); }
    85.71429% {
      transform: translateX(-30vw) translateY(-22vh) scale(0.43); }
    89.28571% {
      transform: translateX(42vw) translateY(19vh) scale(0.43); }
    92.85714% {
      transform: translateX(4vw) translateY(31vh) scale(0.52); }
    96.42857% {
      transform: translateX(-5vw) translateY(-31vh) scale(0.35); }
    100% {
      transform: translateX(-5vw) translateY(39vh) scale(0.52); } }
  
  .firefly:nth-child(12) {
    animation-name: move12; }
    .firefly:nth-child(12)::before {
      animation-duration: 17s; }
    .firefly:nth-child(12)::after {
      animation-duration: 17s, 9610ms;
      animation-delay: 0ms, 3315ms; }
  
  @keyframes move12 {
    0% {
      transform: translateX(-1vw) translateY(-22vh) scale(0.33); }
    4.16667% {
      transform: translateX(-38vw) translateY(26vh) scale(0.69); }
    8.33333% {
      transform: translateX(20vw) translateY(-41vh) scale(0.76); }
    12.5% {
      transform: translateX(29vw) translateY(43vh) scale(0.4); }
    16.66667% {
      transform: translateX(4vw) translateY(42vh) scale(0.92); }
    20.83333% {
      transform: translateX(-43vw) translateY(7vh) scale(0.92); }
    25% {
      transform: translateX(-24vw) translateY(34vh) scale(0.5); }
    29.16667% {
      transform: translateX(15vw) translateY(-49vh) scale(0.26); }
    33.33333% {
      transform: translateX(-33vw) translateY(-35vh) scale(0.46); }
    37.5% {
      transform: translateX(9vw) translateY(-15vh) scale(0.3); }
    41.66667% {
      transform: translateX(27vw) translateY(7vh) scale(0.4); }
    45.83333% {
      transform: translateX(46vw) translateY(18vh) scale(0.59); }
    50% {
      transform: translateX(33vw) translateY(-38vh) scale(0.3); }
    54.16667% {
      transform: translateX(7vw) translateY(50vh) scale(0.65); }
    58.33333% {
      transform: translateX(-29vw) translateY(19vh) scale(0.84); }
    62.5% {
      transform: translateX(46vw) translateY(-11vh) scale(0.86); }
    66.66667% {
      transform: translateX(8vw) translateY(-5vh) scale(0.71); }
    70.83333% {
      transform: translateX(41vw) translateY(-43vh) scale(0.26); }
    75% {
      transform: translateX(-21vw) translateY(2vh) scale(0.44); }
    79.16667% {
      transform: translateX(-19vw) translateY(42vh) scale(0.68); }
    83.33333% {
      transform: translateX(18vw) translateY(15vh) scale(0.3); }
    87.5% {
      transform: translateX(-17vw) translateY(-42vh) scale(0.26); }
    91.66667% {
      transform: translateX(23vw) translateY(0vh) scale(0.71); }
    95.83333% {
      transform: translateX(-48vw) translateY(48vh) scale(0.64); }
    100% {
      transform: translateX(-6vw) translateY(-10vh) scale(0.88); } }
  
  .firefly:nth-child(13) {
    animation-name: move13; }
    .firefly:nth-child(13)::before {
      animation-duration: 11s; }
    .firefly:nth-child(13)::after {
      animation-duration: 11s, 5423ms;
      animation-delay: 0ms, 8334ms; }
  
  @keyframes move13 {
    0% {
      transform: translateX(-27vw) translateY(-41vh) scale(0.9); }
    3.84615% {
      transform: translateX(33vw) translateY(-42vh) scale(0.63); }
    7.69231% {
      transform: translateX(-47vw) translateY(33vh) scale(0.73); }
    11.53846% {
      transform: translateX(31vw) translateY(49vh) scale(0.66); }
    15.38462% {
      transform: translateX(-4vw) translateY(28vh) scale(0.43); }
    19.23077% {
      transform: translateX(25vw) translateY(23vh) scale(0.91); }
    23.07692% {
      transform: translateX(6vw) translateY(36vh) scale(0.88); }
    26.92308% {
      transform: translateX(0vw) translateY(25vh) scale(0.95); }
    30.76923% {
      transform: translateX(31vw) translateY(33vh) scale(0.86); }
    34.61538% {
      transform: translateX(19vw) translateY(-27vh) scale(0.61); }
    38.46154% {
      transform: translateX(37vw) translateY(-32vh) scale(0.36); }
    42.30769% {
      transform: translateX(43vw) translateY(-5vh) scale(0.28); }
    46.15385% {
      transform: translateX(-46vw) translateY(0vh) scale(0.42); }
    50% {
      transform: translateX(26vw) translateY(-3vh) scale(0.81); }
    53.84615% {
      transform: translateX(-8vw) translateY(42vh) scale(0.47); }
    57.69231% {
      transform: translateX(30vw) translateY(-8vh) scale(1); }
    61.53846% {
      transform: translateX(40vw) translateY(42vh) scale(0.78); }
    65.38462% {
      transform: translateX(-48vw) translateY(-40vh) scale(0.31); }
    69.23077% {
      transform: translateX(17vw) translateY(-15vh) scale(0.68); }
    73.07692% {
      transform: translateX(46vw) translateY(4vh) scale(0.86); }
    76.92308% {
      transform: translateX(48vw) translateY(31vh) scale(0.81); }
    80.76923% {
      transform: translateX(3vw) translateY(-6vh) scale(0.67); }
    84.61538% {
      transform: translateX(-21vw) translateY(49vh) scale(0.84); }
    88.46154% {
      transform: translateX(50vw) translateY(-28vh) scale(0.79); }
    92.30769% {
      transform: translateX(13vw) translateY(-33vh) scale(0.6); }
    96.15385% {
      transform: translateX(42vw) translateY(-14vh) scale(0.97); }
    100% {
      transform: translateX(-16vw) translateY(38vh) scale(0.34); } }
  
  .firefly:nth-child(14) {
    animation-name: move14; }
    .firefly:nth-child(14)::before {
      animation-duration: 14s; }
    .firefly:nth-child(14)::after {
      animation-duration: 14s, 10712ms;
      animation-delay: 0ms, 3097ms; }
  
  @keyframes move14 {
    0% {
      transform: translateX(36vw) translateY(-2vh) scale(0.84); }
    4.16667% {
      transform: translateX(2vw) translateY(0vh) scale(0.66); }
    8.33333% {
      transform: translateX(43vw) translateY(9vh) scale(0.29); }
    12.5% {
      transform: translateX(23vw) translateY(21vh) scale(0.56); }
    16.66667% {
      transform: translateX(47vw) translateY(-14vh) scale(0.51); }
    20.83333% {
      transform: translateX(-8vw) translateY(7vh) scale(0.36); }
    25% {
      transform: translateX(10vw) translateY(-32vh) scale(0.39); }
    29.16667% {
      transform: translateX(50vw) translateY(-42vh) scale(0.47); }
    33.33333% {
      transform: translateX(39vw) translateY(-33vh) scale(0.42); }
    37.5% {
      transform: translateX(39vw) translateY(-47vh) scale(0.28); }
    41.66667% {
      transform: translateX(23vw) translateY(23vh) scale(0.64); }
    45.83333% {
      transform: translateX(45vw) translateY(30vh) scale(0.62); }
    50% {
      transform: translateX(-12vw) translateY(-29vh) scale(0.26); }
    54.16667% {
      transform: translateX(-47vw) translateY(-4vh) scale(0.91); }
    58.33333% {
      transform: translateX(8vw) translateY(-19vh) scale(0.47); }
    62.5% {
      transform: translateX(6vw) translateY(-39vh) scale(1); }
    66.66667% {
      transform: translateX(-42vw) translateY(-41vh) scale(0.76); }
    70.83333% {
      transform: translateX(-13vw) translateY(22vh) scale(0.62); }
    75% {
      transform: translateX(39vw) translateY(-47vh) scale(0.39); }
    79.16667% {
      transform: translateX(12vw) translateY(2vh) scale(0.97); }
    83.33333% {
      transform: translateX(41vw) translateY(-49vh) scale(0.33); }
    87.5% {
      transform: translateX(44vw) translateY(-17vh) scale(0.91); }
    91.66667% {
      transform: translateX(-28vw) translateY(38vh) scale(0.29); }
    95.83333% {
      transform: translateX(-47vw) translateY(-38vh) scale(0.71); }
    100% {
      transform: translateX(-4vw) translateY(20vh) scale(0.27); } }
  
  .firefly:nth-child(15) {
    animation-name: move15; }
    .firefly:nth-child(15)::before {
      animation-duration: 10s; }
    .firefly:nth-child(15)::after {
      animation-duration: 10s, 6845ms;
      animation-delay: 0ms, 5123ms; }
  
  @keyframes move15 {
    0% {
      transform: translateX(1vw) translateY(47vh) scale(0.27); }
    5.26316% {
      transform: translateX(-14vw) translateY(-43vh) scale(0.39); }
    10.52632% {
      transform: translateX(-42vw) translateY(38vh) scale(0.54); }
    15.78947% {
      transform: translateX(-1vw) translateY(6vh) scale(0.69); }
    21.05263% {
      transform: translateX(-46vw) translateY(33vh) scale(0.38); }
    26.31579% {
      transform: translateX(50vw) translateY(-46vh) scale(0.63); }
    31.57895% {
      transform: translateX(-35vw) translateY(-10vh) scale(0.74); }
    36.84211% {
      transform: translateX(24vw) translateY(9vh) scale(0.43); }
    42.10526% {
      transform: translateX(27vw) translateY(-3vh) scale(0.44); }
    47.36842% {
      transform: translateX(-6vw) translateY(-35vh) scale(0.44); }
    52.63158% {
      transform: translateX(26vw) translateY(-27vh) scale(0.85); }
    57.89474% {
      transform: translateX(19vw) translateY(16vh) scale(0.67); }
    63.15789% {
      transform: translateX(44vw) translateY(-20vh) scale(0.49); }
    68.42105% {
      transform: translateX(27vw) translateY(-44vh) scale(0.97); }
    73.68421% {
      transform: translateX(-29vw) translateY(50vh) scale(0.46); }
    78.94737% {
      transform: translateX(-2vw) translateY(31vh) scale(0.77); }
    84.21053% {
      transform: translateX(-26vw) translateY(44vh) scale(0.86); }
    89.47368% {
      transform: translateX(-21vw) translateY(-17vh) scale(0.31); }
    94.73684% {
      transform: translateX(26vw) translateY(19vh) scale(0.77); }
    100% {
      transform: translateX(-26vw) translateY(-30vh) scale(0.93); } }
  
  @keyframes drift {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  
  @keyframes flash {
    0%, 30%, 100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw blue; }
    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw blue; } }