h2 {
    color: var(--global-color-primary);
}

.travel-card-wrapper {
    margin-bottom: 30px;
}

.travel-card {
    height: 100%;
}

.travel-card-img {
    height: 300px;
}

.travel-text  {
    font: var(--global-body-font);
    color: var(--global-text-color);
}

.travel-highlighted-text {
    color: var(--global-color-primary);
    font-weight: 600;
    text-decoration: none;
}

.travel-button {
    background-color: var(--global-color-primary);
    border: solid 2px var(--global-color-primary);
    position: absolute;
    bottom: 0;
    margin-bottom: 15px;
}

.travel-button:hover, .travel-button:active {
    font-weight: 600;
    background-color: #fff;
    color: var(--global-color-primary);
    border: solid 2px var(--global-color-primary);
}

.accomodation-icon {
    width: 100%;
	border-radius: 50%;
    cursor: pointer;
    transition: transform .2s;
}

.accomodation-icon:hover {
    transform: scale(1.1);
}

.travel-summary {
    font: var(--global-body-font);
    text-align: center;
    color: var(--global-text-color);
    width: 80%;
    display: block;
    margin: 0 auto 20px auto;
}

@media screen and (min-width: 768px) {
    .travel-card-wrapper {
        flex: 1;
        margin-bottom: 0;
    }

    .travel-divider {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .accomodation-icon {
        width: 50%;
        margin: 0 auto 20px auto;
        display: block;
    }
}