.gallery-summary {
    font: var(--global-body-font);
    text-align: center;
    color: var(--global-text-color);
    width: 80%;
    display: block;
    margin: 0 auto 20px auto;
}

.gallery-summary-emphasized {
    color: var(--global-color-primary);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

.input-select-wrapper {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    transition: 0.75s;
}

.input-select-wrapper:focus-within {
   width: 100%;
  }

.filter-search {
    font-family: var(--global-section-heading-font);
    color: var(--global-color-primary) !important;
    font-size: 20px;
    font-weight: 800;
    border: 2px solid var(--global-color-primary);
    border-radius: 20px;
    transition: 0.75s;
}

.filter-search:focus {
    color: #4b515d;
    border-color: var(--global-color-primary);
    box-shadow: 0 0 5pt 2pt var(--global-color-primary);
    outline-width: 0px;
    width: 100%;
}

.filter-search::placeholder { 
    color: var(--global-color-primary);
  }

.gallery-buttons-wrapper {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.gallery-button-wrapper {
    width: 50%;
}

.category-input {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid var(--global-color-primary);
    color: var(--global-color-primary); 
}

input[type="file"] {
    display: none;
}

.image-upload-button {
    background-color: var(--global-color-primary);
    color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 150px;
    padding: 6px 12px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .input-select-wrapper {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .gallery-buttons-wrapper {
        width: 100%;
    }
}