.song-input-wrapper {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    transition: 0.75s;
}

.song-input-wrapper:focus-within {
   width: 100%;
  }

.search-bar {
    font-family: var(--global-section-heading-font);
    color: var(--global-text-color);
    font-size: 20px;
    border-radius: 20px;
    transition: 0.75s;
}

.search-bar:focus {
    color: #4b515d;
    border-color: var(--global-color-primary);
    box-shadow: 0 0 5pt 2pt var(--global-color-primary);
    outline-width: 0px;
    width: 100%;
}

.seach-results {
    width: 100%;
    position: absolute;
    color: grey;
}

.search-results-list {
    padding: 0;
    z-index: 100;
}

/* Search Result Styling */

.search-result {
    width: 100%;
    background: #fbfbfb;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin: 0;
    font: var(--global-body-font);
}

.search-result:hover {
    cursor: pointer;
    background: #ddd;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.search-img-container {
    padding: 0;
    margin: 0;
    line-height: 0px;
}

.search-album-cover {
    width: 100%;
    margin: 0;
    padding: 0;
}

.search-item-conatiner {
    height: 110px;
    line-height: 110px;
    color: #a9a9a9;
}

@media screen and (max-width: 768px) {
    .song-input-wrapper {
        width: 100%;
    }
}