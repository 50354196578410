#header-wrapper {
    display: inline-block;
    width: 100%;
    height: 80vh;
    overflow-y: hidden;
    background-size: cover;
}

.header-heading {
    font-family: var(--global-heading-font);
    font-size: 64px;
    color: white;
    width: 450px;
    margin: 30vh 0 0 50px;
    position: absolute;
    text-align: left;
}

@media screen and (max-width: 825px) {
    #header-wrapper {
        height: 50vh;
    }
    .header-heading {
        width: 300px;
        font-size: 40px;
        margin: 20vh 0 0 30px;
    }
}

@media screen and (max-width: 500px) {
    #header-wrapper {
        height: 25vh;
    }
    .header-heading {
        margin: 50px 0 0 30px;
    }
}