* {
    scroll-behavior: smooth;
}

.story-item {
    margin-top: 30px;
}

.story-img {
    height: 100%;
}

/* Full Page Timeline */
.story-wrapper {
    height: 100vh;
    color: #fff;
    position: relative;
  }

  .story-section {
    height: 100vh;
    font-size: 20px;
    font-weight: 100;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .story-section1 {
    background-image: url("https://www.lboro.ac.uk/media/media/about/images/about-meta-hazlerigg.jpg");
    background-position: center;
  }
  .story-section2 {
    background-image: url("https://storage.googleapis.com/matt-becca-test-image-assets/story-photos/2015.png");
    background-position: top;
  }
  .story-section3 {
    background-image: url("https://storage.googleapis.com/matt-becca-test-image-assets/story-photos/2017.png");
    background-position: center;
  }
  .story-section4 {
    background-image: url("https://storage.googleapis.com/matt-becca-test-image-assets/story-photos/2019.png");
    background-position: center;
  }
  .story-section5 {
    background-image: url("https://storage.googleapis.com/matt-becca-test-image-assets/story-photos/2020.png");
    background-position: center;
  }
  .story-section6 {
    background-image: url("https://storage.googleapis.com/matt-becca-test-image-assets/story-photos/2021.png");
    background-position: top;
  }
  .story-section7 {
    background-image: url("https://storage.googleapis.com/matt-becca-test-image-assets/story-photos/2023.jpg");
    background-position: center;
  }
  .nav__wrapper {
    position: fixed;
    width: 30px;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
  }
  .nav {
    margin: 0 0 0 30px;
  }
  .nav__counter {
    font-family: var(--global-heading-font);
    font-size: 24px;
    transition: all 0.15s ease-out;
    text-decoration: none;
  }
  .nav__title {
    font-family: var(--global-heading-font);
    font-size: 32px;
    font-weight: 300;
    color: #fff;
    margin: 0 0 0.25em;
    width: 500px;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: height 0.3s ease-out, opacity 0.2s ease-out;
    text-decoration: none;
  }
  .nav__body {
    font-family: var(--global-body-font);
    font-weight: 300;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 90vw;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-out, opacity 0.2s ease-out;
    text-decoration: none;
  }
  .nav li {
    position: relative;
    transition: all 0.3s ease-out;
    margin-bottom: 1em;
    text-decoration: none;
  }
  .nav li:after {
    content: '';
    display: block;
    border-left: 2px solid white;
    border-top: 2px solid white;
    height: 250px;
    width: 20px;
    position: absolute;
    left: -30px;
    top: 15px;
    text-decoration: none;
  }
  .nav li a {
    display: block;
    padding: 0;
    color: #fff;
    transition: all 0.15s ease-out;
    text-decoration: none;
  }
  .nav li a:hover {
    background-color: transparent;
    padding-left: 1em;
    text-decoration: none;
  }
  .nav li a:focus {
    background-color: transparent;
    text-decoration: none;
  }
  .nav li.active {
    pointer-events: none;
    padding-left: 1em;
    text-decoration: none;
  }
  .nav li.active:after {
    width: 35px;
    height: 400px;
    top: 35px;
    text-decoration: none;
  }
  .nav li.active .nav__counter {
    font-size: 48px;
  }
  .nav li.active .nav__title {
    height: 40px;
    opacity: 1;
    overflow: visible;
  }
  .nav li.active .nav__body {
    height: 100px;
    opacity: 1;
    overflow: visible;
  }

  @media screen and (max-height: 760px) {

    .nav__wrapper {
      padding-bottom: 0;
    }
    .nav__counter {
      font-size: 22px;
    }
    .nav__title {
      font-size: 20px;
      margin: 0 0 0.12em;
    }
    .nav__body {
      font-size: 14px;
    }
    .nav li {
      margin-bottom: 0.5em;
    }
    .nav li:after {
      height: 120px;
    }

    .nav li.active .nav__counter {
      font-size: 32px;
    }
    .nav li.active .nav__title {
      height: 20px;
    }
    .nav li.active .nav__body {
      margin-top: 10px;
      height: 50px;
    }
    
  }


  @media screen and (max-width: 720px) {

    .nav li.active .nav__body {
      width: 80vw;
    }

    .nav__body__engaged {
      font-size: 9px;
    }

  }

  @media screen and (max-width: 694px) {

    .nav__counter {
      font-size: 20px;
    }
    .nav__title {
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 0.3em;
    }
    .nav__body {
      font-size: 14px;
      font-weight: 500;
    }
    .nav li {
      margin-bottom: 1em;
    }
    .nav li:after {
      height: 92px;
    }

    .nav li.active .nav__counter {
      font-size: 24px;
    }
    .nav li.active .nav__title {
      height: 10px;
    }
    .nav li.active .nav__body {
      margin-top: 10px;
      height: 70px;
    }
  }

  @media screen and (max-width: 500px) {

    .nav li.active .nav__body {
      width: 75vw;
    }

  }

  @media screen and (max-width: 350px) {

    .nav li.active .nav__body {
      width: 70vw;
    }

  }


  @media screen and (max-width: 450px) {

    .nav__body__primary {
      display: none;
    }

  }

  @media screen and (min-width: 450px) {

  .nav__body__alt {
    display: none;
  }
}

@media screen and (max-height: 570px) {

  .nav__counter {
    font-size: 12px;
  }
  .nav__title {
    font-size: 11px;
    margin: 0 0 0.3em;
  }
  .nav__body {
    font-size: 9px;
  }
  .nav li {
    margin-bottom: 0.12em;
  }
  .nav li:after {
    height: 66px;
  }

  .nav li.active .nav__counter {
    font-size: 15px;
  }
  .nav li.active .nav__title {
    height: 10px;
  }
  .nav li.active .nav__body {
    margin-top: 5px;
    height: 50px;
  }

}