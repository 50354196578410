.footer-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
}

.footer-icon {
    width: 135px;
    padding-bottom: 30px;
}

.footer-navlink {
    color: var(--global-color-primary);
}

.footer-navlink:hover {
    color: var(--global-color-secondary);
}

.footer-body-font {
    font: var(--global-body-font);
    color: var(--global-text-color);
    margin-left: 0 !important;
}