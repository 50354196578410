#questions {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.questions-text  {
    font: var(--global-body-font);
    color: var(--global-text-color);
    text-align: center;
    margin-top: -40px;
    margin-bottom: 20px;
}