
#schedule {
    position: relative;
}

#schedule:before {
    content: '';
    background: var(--global-color-secondary);
    width: 5px;
    height: 95%;
    position: absolute;
    left: 50%;
    opacity: 0.2;
    transform: translateX(-50%);
}

.schedule-item {
    width: 100%;
    margin-bottom: 70px;
}

.schedule-item:nth-child(even) .schedule-content {
    float: right;
    padding: 40px 30px 10px 30px;
}

.schedule-item:nth-child(even) .schedule-content .date {
    right: auto;
    left: 0;
}

.schedule-item:nth-child(even) .schedule-content::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #f5f5f5 transparent transparent;
}

.schedule-item::after {
    content: '';
    display: block;
    clear: both;
}

.schedule-content {
    position: relative;
    width: 45%;
    padding: 10px 30px;
    border-radius: 4px;
    background: #f5f5f5;
    box-shadow: 0 20px 25px -15px rgba(0, 0, 0, .3);
}

.schedule-content::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    right: -15px;
    border-width: 10px 0 10px 15px;
    border-color: transparent transparent transparent #f5f5f5;
}

.schedule-img {
    width: 30px;
    height: 30px;
    background: var(--global-color-secondary);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 25px;
    margin-left: -15px;
}

.schedule-card {
    padding: 0 !important;
}

.schedule-card p {
    padding: 0 20px;
}

.schedule-card a {
    margin-left: 20px;
}

.schedule-img-header {
    height: 200px;
    position: relative;
    margin-bottom: 20px;
}

.schedule-img-header h2 {
    color: #fff;
    position: absolute;
    bottom: 5px;
    left: 20px;
}

blockquote {
    margin-top: 30px;
    color: #757575;
    border-left-color: var(--global-color-secondary);
    padding: 0 20px 20px 20px;
}

.date {
    background: var(--global-color-primary);
    display: inline-block;
    color: #fff;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

/* Schedule Images */

.guest-arrival {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4)), url(
        https://storage.googleapis.com/matt-becca-test-image-assets/schedule-photos/guest-arrival.jpeg
   ) center center no-repeat;
    background-size: cover;
}

.ceremony-starts {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4)), url(
        https://storage.googleapis.com/matt-becca-test-image-assets/schedule-photos/ceremony-starts.jpg
   ) center center no-repeat;
    background-size: cover;
}

.wedding-breakfast {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4)), url(
        https://storage.googleapis.com/matt-becca-test-image-assets/schedule-photos/wedding-breakfast.jpg
   ) center center no-repeat;
    background-size: cover;
}

.wedding-evening {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4)), url(
        https://storage.googleapis.com/matt-becca-test-image-assets/schedule-photos/wedding-evening.jpg
   ) center center no-repeat;
    background-size: cover;
}

.wedding-disco {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4)), url(
        https://storage.googleapis.com/matt-becca-test-image-assets/schedule-photos/wedding-disco.jpg
   ) center center no-repeat;
    background-size: cover;
}

@media screen and (max-width: 768px) {
    .schedule::before {
        left: 50px;
   }
    .schedule .schedule-img {
        left: 50px;
   }
    .schedule .schedule-content {
        max-width: 100%;
        width: auto;
        margin-left: 70px;
   }
    .schedule .schedule-item:nth-child(even) .schedule-content {
        float: left;
   }
    .schedule .schedule-item:nth-child(odd) .schedule-content::after {
        content: '';
        position: absolute;
        border-style: solid;
        width: 0;
        height: 0;
        top: 30px;
        left: -15px;
        border-width: 10px 15px 10px 0;
        border-color: transparent #f5f5f5 transparent transparent;
   }

}

@media screen and (max-width: 700px) {
    .schedule-img-header {
        border-radius: 20px;
        margin-bottom: 0;
    }

    .schedule-text {
        display: none;
    }
}

@media screen and (max-width: 375px) {
    .schedule-img-header h2 {
        font-size: 24px;
   }
}