.navbar {
    position: fixed;
    height: 50px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: .3s ease-in;
    overflow: hidden;
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.navbar-active {
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 29px 0px;
}

/* Menu List */

.menu-items {
    list-style: none;
    position: absolute;
    right: 0;
    margin-right: 10px;
    float: right;
    z-index: 1000;
    font-family: var(--global-heading-font);
    font-size: 22px;
    font-weight: 500;
}

.menu-item {
    color: var(--global-text-color);
    border: none;
    margin: 18px 40px 0 0;
    display: block;
	float: right;
}

.menu-item-link {
    text-decoration: none;
    color: var(--global-text-color);
}

.menu-item-link-white {
    text-decoration: none;
    color: var(--global-text-color);
    color: #fff;
}

.menu-item-link:hover {
    cursor: pointer;
    color: var(--global-color-primary);
    font-weight: 700;
}

.menu-item-link-white:hover {
    cursor: pointer;
    color: #fff;
    font-weight: 700;
}

/* Hamburger Menu */

.hamburger-menu {
    position: absolute;
    right: 0;
    margin-right: 10px;
    z-index: 1000;
    cursor: pointer;
    transition: .3s ease-in;
}

.hamburger-logo {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.hamburger-menu-item {
    border: none;
    font-family: var(--global-section-heading-font);
    font-size: 22px;
    font-weight: 500;
    color: var(--global-color-primary);
}

.hamburger-menu-item:hover {
    color: #fff;
    background-color: var(--global-color-primary);
}

@media screen and (max-width: 1064px) {
    .hamburger-menu {
        width: 32px;
    }
}

@media screen and (max-width: 600px) {
    .hamburger-menu {
        width: 28px;
    }
}