body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --global-body-font: 300 17px/26px Roboto Condensed;
  --global-heading-font: 'Parisienne', cursive;
  --global-section-heading-font: 'Great Vibes';
  --global-text-color: #777;
  --global-color-primary: #6F3092;
  --global-color-secondary: #B70062;
}

/* Submit Button Styling */

.submit-button{ 
  font-family: var(--global-heading-font);
  display: block;
  width: 80%;
  padding: .4rem;
  border: none; 
  margin: 1rem auto; 
  font-size: 1.3rem;
  background: var(--global-color-primary);
  color: #fff;
  border-radius: 3px; 
  cursor: pointer;
  transition: .3s all;
}

.submit-button:hover { 
   background: var(--global-color-secondary);
}