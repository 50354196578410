 
.button-wrapper{
    margin: 20px 0 20px 0;
}

button {
    text-decoration: none;
    color: inherit;
    border: none;
    background: none; 
}

button:hover {
  cursor: pointer;
}
  
  .cta {
    position: relative;
    margin: auto;
    padding: 19px 22px;
    color: var(--global-text-color);
    transition: all .2s ease; }
    .cta:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      border-radius: 28px;
      background: rgba(183, 0, 98, 0.5);
      width: 56px;
      height: 56px;
      transition: all .3s ease; }
    .cta span {
      position: relative;
      font-size: 16px;
      line-height: 18px;
      font-weight: 900;
      letter-spacing: .25em;
      text-transform: uppercase;
      vertical-align: middle; }
    .cta svg {
      position: relative;
      top: 0;
      margin-left: 10px;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: #111;
      stroke-width: 2;
      transform: translateX(-5px);
      transition: all .3s ease; }
    .cta:hover:before {
      width: 100%;
      background: #B70062;
      color: #fff;
      text-decoration: none; }
    .cta:hover svg {
      transform: translateX(0); }
    .cta span{
      transition: all .3s ease;
    }
    .cta:hover span{
      color: #fff;
    }
    .cta:active {
      transform: scale(0.96); }